import { GameLength } from '../config/config';

export async function checkGuessValid(
  guess: string,
  prevGuesses: string[],
  len: GameLength
) {
  if (prevGuesses.map((g) => g.toLowerCase()).includes(guess.toLowerCase())) {
    return false;
  }
  const words = await import(`../data/words_${len}.json`);

  return (words.default as string[]).includes(guess.toLowerCase());
}
