import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import ReactGA from 'react-ga4';
import { getEnv } from './functions/getEnv';

const envIds = {
  prod: 'G-XNJ146NY7P',
  stage: 'G-7VVG5QBEJP',
  test: 'G-EF2M806DF4',
};

ReactGA.initialize(envIds[getEnv()]);
ReactGA.send('pageview');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
