import { GameLength, GAME_LENGTHS } from '../../config/config';
import { getGameStatusesLocalStorage } from '../../functions/localStorageUtils';
import { GameStatus } from '../../models/status';
import './StatsView.css';

type StatsDict = {
  [gameLength in GameLength]: GameStatus[];
};

function StatsView() {
  const gameStats = GAME_LENGTHS.reduce((stats, len) => {
    stats[len] = Object.values(getGameStatusesLocalStorage(len));
    return stats;
  }, {} as StatsDict);

  return (
    <div className="stats-view">
      {GAME_LENGTHS.map((len) => {
        return <GameLengthStats len={len} stats={gameStats[len]} key={len} />;
      })}
    </div>
  );
}

function GameLengthStats({
  len,
  stats,
}: {
  len: GameLength;
  stats: GameStatus[];
}) {
  const completedGames = stats.filter((g) => g.guessesToSolve != null);

  const guessesData = [] as [number, number][];

  for (let i = 1; i <= 10; i++) {
    guessesData.push([i, 0]);
  }

  completedGames.forEach((game) => {
    const guesses = game.guessesToSolve!;

    if (guesses > 10) {
      guessesData[9][1] += 1;
    } else {
      guessesData[guesses - 1][1] += 1;
    }
  });

  const maxCnt = Math.max(...guessesData.map((d) => d[1]));

  let chart = <div />;

  if (maxCnt > 0) {
    chart = (
      <div className="stats-chart">
        {guessesData.map((d) => {
          if (maxCnt === 0) {
            return <div className="bar bar-null" key={d[0]} />;
          }

          const isZero = d[1] === 0;

          return (
            <div
              className={'bar' + (isZero ? ' bar-null' : '')}
              style={{
                height: `calc(100% * ${d[1] / maxCnt})`,
              }}
              key={d[0]}
            >
              {isZero ? <span>&nbsp;</span> : d[1]}
              <span className="bar-label">{d[0]}</span>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <>
      <h3>{len}-dles</h3>
      <h5>Completed Games: {completedGames.length}</h5>
      {chart}
    </>
  );
}

export default StatsView;
