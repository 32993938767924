import { GameLength } from '../config/config';
import { Dle } from '../models/dles';
import { GameStatus } from '../models/status';

export function saveGameLocalStorage(game: Dle) {
  window.localStorage.setItem(
    `${game.length}/${game.id}`,
    JSON.stringify(game)
  );
}

export function getGameLocalStorage(len: GameLength, id: number) {
  const savedGame = window.localStorage.getItem(`${len}/${id}`);

  if (savedGame != null) {
    return JSON.parse(savedGame);
  }

  return null;
}

export function getGameStatusesLocalStorage(len: GameLength) {
  const statusMap: {
    [id: number]: GameStatus;
  } = {};

  Object.keys(localStorage).forEach((k) => {
    const [gameLen, id] = k.split('/').map(Number);

    if (gameLen === len) {
      const game = JSON.parse(localStorage.getItem(k) as string);

      const solved =
        game.guesses[game.guesses.length - 1]?.toLowerCase() ===
        game.solution.toLowerCase();

      statusMap[id] = {
        id,
        guessesToSolve: solved ? game.guesses.length : null,
        inProgress: !solved && game.guesses.length > 0,
      };
    }
  });

  return statusMap;
}
