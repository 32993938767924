import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faChartSimple } from '@fortawesome/free-solid-svg-icons';

import './HeaderBar.css';

function HeaderBar() {
  let envBadge = null;

  if (process.env.REACT_APP_ENV === 'prod') {
    envBadge = null;
  } else if (process.env.REACT_APP_ENV === 'stage') {
    envBadge = <span className="env-badge">BETA</span>;
  } else {
    envBadge = <span className="env-badge">TEST</span>;
  }

  return (
    <header className="header">
      <Link to="/">
        <div className="header-btn header-home-btn">
          <FontAwesomeIcon icon={faHouse} />
        </div>
      </Link>
      <Link to="/">
        <div className="header-title">Mordle{envBadge}</div>
      </Link>
      <Link to="/stats" className="header-stats-btn-holder">
        <div className="header-btn header-stats-btn">
          <FontAwesomeIcon icon={faChartSimple} />
        </div>
      </Link>
      {/* <Link to="/settings">
        <div className="header-btn header-settings-btn">
          <FontAwesomeIcon icon={faGear} />
        </div>
      </Link> */}
    </header>
  );
}

export default HeaderBar;
