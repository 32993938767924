import { GameLength, GAME_LENGTHS } from '../config/config';
import {
  getGameLocalStorage,
  getGameStatusesLocalStorage,
  saveGameLocalStorage,
} from '../functions/localStorageUtils';
import { Dle } from '../models/dles';

type SolutionDict = {
  [gameLength in GameLength]: Promise<string[]>;
};

class GameState {
  private solutions: SolutionDict;

  constructor() {
    this.solutions = {} as SolutionDict;

    GAME_LENGTHS.forEach((l) => {
      this.getSolutions(l);
    });
  }

  async getSolutions(len: GameLength) {
    if (this.solutions[len] != null) {
      return this.solutions[len];
    }

    this.solutions[len] = (
      await import(`../data/solutions_${len}.json`)
    ).default;

    return this.solutions[len];
  }

  async getGameStatuses(len: GameLength) {
    const solutions = await this.getSolutions(len);
    const statuses = getGameStatusesLocalStorage(len);

    solutions.forEach((s, i) => {
      if (statuses[i + 1] === undefined) {
        statuses[i + 1] = {
          id: i + 1,
          guessesToSolve: null,
          inProgress: false,
        };
      }
    });

    return statuses;
  }

  saveGame(game: Dle) {
    saveGameLocalStorage(game);
  }

  async getGame(len: GameLength, id: number): Promise<Dle> {
    const savedGame = getGameLocalStorage(len, id);

    if (savedGame != null) {
      return new Dle(savedGame);
    }

    const solution = (await this.solutions[len])[id - 1];

    return new Dle({
      length: len,
      id,
      solution,
      guesses: [],
    });
  }
}

export const gameState = new GameState();
